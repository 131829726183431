<template>
  <div>
    <div
      class="h-100 w-100 align-items-center justify-content-center"
      style="color:#1FFC35;text-align:center;margin-top:20px;"
    >
      <div style="margin-bottom:-30px;">
        MEGA PRIZE POT
      </div>
      <div style="font-size:6rem;">
        £5000
      </div>
      <div style="margin-top:-20px;">
        UP FOR GRABS EVERY DRAW!
      </div>
      <div style="border-top:solid 1px #1FFC35;width: 80%;margin-left: 10%;margin-top: 15px;" />
      <div style="color:white;margin-top: 30px;font-size:1.3rem;">
        NEXT DRAW IN
      </div>
    </div>
    <div class="h-100 w-100 d-flex align-items-center justify-content-center">
      <FlipCountdown
        style="width: 100%;"
        :large="true"
      />
    </div>
  </div>
</template>

<script>
import FlipCountdown from './flipCountdown.vue'

export default {
  name: 'LargeCountdown',
  components: {
    FlipCountdown
  }
}
</script>

<style scoped>

</style>